export const plans: {
  START_PLAN_ID: string;
  FIRST_PLAN_ID: string;
  FIRST_MONTHLY_PLAN_ID: string;
  RESOLD_PLAN_ID: string;
  RESOLD_MONTHLY_PLAN_ID: string;
  RESOLD_EXTERNAL_MONTHLY_PLAN_ID: string,
  RESOLD_EXTERNAL_PLAN_ID: string,
  MULTI_COMPANY_PLAN_ID: string;
  MULTI_COMPANY_MONTHLY_PLAN_ID: string;
  RESOLD_MULTI_COMPANY_PLAN_ID: string;
  RESOLD_MULTI_COMPANY_MONTHLY_PLAN_ID: string;
  RESOLD_EXTERNAL_MULTI_COMPANY_PLAN_ID: string;
  RESOLD_EXTERNAL_MULTI_COMPANY_MONTHLY_PLAN_ID: string;
  PARTNER_PLAN_ID: string;
  LIGHT_PLAN_ID: string;
} = {
  START_PLAN_ID: "START_PLAN_ID",
  FIRST_PLAN_ID: "FIRST_PLAN_ID",
  FIRST_MONTHLY_PLAN_ID: "FIRST_MONTHLY_PLAN_ID",
  RESOLD_PLAN_ID: "RESOLD_PLAN_ID",
  RESOLD_MONTHLY_PLAN_ID: "RESOLD_MONTHLY_PLAN_ID",
  RESOLD_EXTERNAL_MONTHLY_PLAN_ID: "RESOLD_EXTERNAL_MONTHLY_PLAN_ID",
  RESOLD_EXTERNAL_PLAN_ID: "RESOLD_EXTERNAL_PLAN_ID",
  MULTI_COMPANY_PLAN_ID: "MULTI_COMPANY_PLAN_ID",
  MULTI_COMPANY_MONTHLY_PLAN_ID: "MULTI_COMPANY_MONTHLY_PLAN_ID",
  RESOLD_MULTI_COMPANY_PLAN_ID: "RESOLD_MULTI_COMPANY_PLAN_ID",
  RESOLD_MULTI_COMPANY_MONTHLY_PLAN_ID: "RESOLD_MULTI_COMPANY_MONTHLY_PLAN_ID",
  RESOLD_EXTERNAL_MULTI_COMPANY_PLAN_ID: "RESOLD_EXTERNAL_MULTI_COMPANY_PLAN_ID",
  RESOLD_EXTERNAL_MULTI_COMPANY_MONTHLY_PLAN_ID: "RESOLD_EXTERNAL_MULTI_COMPANY_MONTHLY_PLAN_ID",
  PARTNER_PLAN_ID: "PARTNER_PLAN_ID",
  LIGHT_PLAN_ID: "LIGHT_PLAN_ID",
};

export const providerEnum: Record<string, string> = {
  axonaut: "axonaut",
  evoliz: "evoliz",
  gesco: "gesco",
  intuit: "intuit",
  sellsy: "sellsy",
};

export const providerName: Record<string, string> = {
  axonaut: "Axonaut",
  evoliz: "Evoliz",
  gesco: "Zenfirst gesco",
  intuit: "Intuit",
  sellsy: "Sellsy",
};

export const roles: any = {
  1: "ADMIN",
  3: "READ",
  4: "READ_WRITE",
};

export const rolesEnum: { ADMIN: number; READ: number; READ_WRITE: number } = {
  ADMIN: 1,
  READ: 3,
  READ_WRITE: 4,
};

export const vatRateList = [0, 2.1, 5.5, 10, 20];
export const delayList = [0, 15, 30, 45, 60];

export const maxLevelDeepth = 4;

export const applicationName = "Zenfirst";

const constants = { plans, maxLevelDeepth, roles, rolesEnum, applicationName };
export default constants;
